import React, { useEffect } from 'react';

function PreloadImages() {
  useEffect(() => {
    // Array of image URLs to preload
    const imageUrls = [
      'home.jpg',
      'adopt.jpg',
      'donate.jpg',
      'shelter.jpg',
      'founders.jpg',
      'residents.jpg',
      'surrender.jpg'
      // Add more image URLs as needed
    ];

    // Preload images
    imageUrls.forEach((imageUrl) => {
      const img = new Image();
      img.src = imageUrl;
    });
  }, []);

  return null; // This component doesn't render anything
}

export default PreloadImages;
