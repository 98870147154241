import { useEffect, useRef, useState } from "react";
import "../../App.scss"
import da from "./da.png"
import Nav from "../nav/nav";
import closee from "./close.png"
import { Link, Outlet, useLocation } from 'react-router-dom';
import osh from "./main/0.jpeg"

function Shelter() {
    const location = useLocation();


   







    return (
        <>


            


            <div className="Residentssss">

                <div className="lower-div-head shphm">
                    <Nav />
                    <div className="adhd">
                        <p className="head-text">
                            Our Shelter
                        </p>

                        <div className="resident-text-box">
                            <p className="resident-text">
                                As founders of We Positive Care Chitragupt Foundation, our journey is fueled by a profound love for all creatures. Driven by empathy and a sense of responsibility, we strive to make a tangible difference in their lives. With backgrounds in animal science and conservation, we bring diverse expertise to our cause.
                            </p>
                        </div>
                    </div>

                </div>

                <div className="lower-div rld">
                    <div className="lower-div-content">
                        {/* <div className="shelter-head">
            <p className="shelter-head-text">
                Please select one choice out of the three choices given below to view our shelter in details.

            </p>
        </div> */}
                        <div className="head-cats">
                            <Link to='/shelter' >
                                <p className={(location.pathname === '/shelter') ? 'head-cat active-cat' : 'head-cat'}>
                                    Overview
                                </p>
                            </Link>
                            <Link to='/shelter/main-section' >
                                <p className={(location.pathname === '/shelter/main-section') ? 'head-cat active-cat' : 'head-cat'}>
                                    Main Section
                                </p>
                            </Link>
                            <Link to='/shelter/distemper-section' >
                                <p className={location.pathname === '/shelter/distemper-section' ? 'head-cat active-cat' : 'head-cat'}>
                                    Distemper Section
                                </p>
                            </Link>
                            <Link to='/shelter/paralysed-section' >
                                <p className={location.pathname === '/shelter/paralysed-section' ? ' head-cat active-cat' : 'head-cat'}>
                                    Paralysed Section
                                </p>
                            </Link>
                        </div>

                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Shelter