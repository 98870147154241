import "../../../App.scss"
import osh from "./0.jpeg"
import tsh from "./1.jpeg"
import thsh from "./2.jpeg"

function Distemper() {
    return (
        <div className="MainSec">

            <div className="shel-main-dets">
                <div className="main-det">
                    <p className="det-head">
                        Overview
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Welcome to our distemper section, a dedicated area designed to provide compassionate care and support for dogs facing the challenges of distemper. Here, we offer a safe and nurturing environment where afflicted dogs receive specialized medical attention, round-the-clock monitoring, and tender loving care. With a team of skilled veterinarians and compassionate staff, we work tirelessly to alleviate symptoms, boost immunity, and provide comfort to our furry patients as they navigate through this illness. Our distemper section is a testament to our unwavering commitment to the well-being of every dog in our care, ensuring they receive the best possible treatment and support during their journey to recovery. Join us in providing hope and healing to dogs in need as we strive to make a difference, one wagging tail at a time.
                        </p>
                        <img className="shel-det-img" src={osh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Seperate Rooms
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Welcome to our separate rooms within the distemper ward, meticulously designed to offer individualized care and attention to each dog battling this challenging illness. Within these rooms, dogs receive personalized treatment plans tailored to their unique needs, ensuring optimal comfort and support throughout their recovery journey. Our dedicated team of veterinary professionals closely monitors each patient, administering medications, providing nourishment, and offering gentle comfort to alleviate symptoms and promote healing. With a focus on maintaining a calm and stress-free environment, these rooms serve as havens of healing where dogs can rest, recuperate, and receive the specialized care they deserve. Together, we stand committed to providing compassionate and comprehensive support to every dog in our distemper ward, empowering them to overcome adversity and thrive once again.
                        </p>
                        <img className="shel-det-img" src={tsh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Well Maintained Rooms
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Within our distemper ward, each single separate room is meticulously crafted to prioritize hygiene, safety, and comfort for the individual dog under our care. These rooms are equipped with state-of-the-art sanitation facilities, ensuring a clean and sterile environment essential for managing contagious diseases like distemper. Our dedicated staff maintains stringent hygiene protocols, including regular cleaning and disinfection, to minimize the risk of cross-contamination and promote a conducive environment for healing. Additionally, these rooms are furnished with cozy bedding, ample space for movement, and soothing ambient lighting to provide a serene retreat where dogs can rest undisturbed. In this secluded sanctuary, every effort is made to nurture the well-being of our canine patients, fostering a sense of security and tranquility as they embark on their path to recovery.
                        </p>
                        <img className="shel-det-img thsh" src={thsh} />
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Distemper