import { Link, useLocation } from "react-router-dom"
import "../../App.scss"
import logo from "./logo.png"
import menu from "./menu.png"


function Nav() {

    const location = useLocation();

    const isActive = (path) => location.pathname === path;

    const closeNav = () => {
        document.getElementById("overlayNav").style.display = "none"
    }


    return (
        <>

            <div className="overlayNav" id="overlayNav">
                <div className="overlayNavBox">
                    <div className="overlayMenu">
                        <p className="overlayMenuItem closeMenu" onClick={closeNav}>Close</p>
                        {/* <Link to='/founders'><p className="overlayMenuItem" onClick={closeNav}>Founders</p></Link> */}
                        <Link to='/shelter'><p className="overlayMenuItem" onClick={closeNav}>About Shelter</p></Link>
                        <Link to='/adopt'><p className="overlayMenuItem" onClick={closeNav}>Adopt</p></Link>
                        <Link to='/surrender'><p className="overlayMenuItem" onClick={closeNav}>Surrender</p></Link>
                        <Link to='/resident-animals'><p className="overlayMenuItem" onClick={closeNav}>Resident Animals</p></Link>
                        <Link to='/donate'><p className="overlayMenuItem" onClick={closeNav}>Donate</p></Link>

                    </div>
                </div>
            </div>

            <div className="Nav">
                <div className="logo-box">
                    <Link to='/'><img className="logo" src={logo} /></Link>
                </div>
                <div className="menu">
                    {/* <Link to='/'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Home</p></Link> */}
                    {/* <Link to='/founders'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Founders</p></Link>
                <Link to='/shelter'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>About Shelter</p></Link>
                <Link to='/adopt'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Adopt</p></Link>
                <Link to='/surrender'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Surrender</p></Link>
                <Link to='/resident-animals'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Resident Animals</p></Link>
                <Link to='/donate'><p className={isActive("/") ? "menu-item" : "menu-item lbh"}>Donate</p></Link> */}

                    {/* <Link to='/founders'><p className="menu-item">Founders</p></Link> */}
                    <Link to='/shelter'><p className="menu-item">About Shelter</p></Link>
                    <Link to='/adopt'><p className="menu-item">Adopt</p></Link>
                    <Link to='/surrender'><p className="menu-item">Surrender</p></Link>
                    <Link to='/resident-animals'><p className="menu-item">Resident Animals</p></Link>
                    <Link to='/donate'><p className="menu-item">Donate</p></Link>
                    <Link to='/surrender'><p className="menu-item">Stories</p></Link>
                    <Link to='/surrender'><p className="menu-item">Activity</p></Link>
                    <img className="menuImg" onClick={() => { document.getElementById("overlayNav").style.display = "flex" }} src={menu} />
                </div>
            </div>
        </>
    )
}

export default Nav