import { useEffect, useRef, useState } from "react";
import "../../App.scss"
import da from "./da.png"
import Nav from "../nav/nav";

function Surrender() {



    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        age: '',
        dname: '',
        gender: '',
        breed: "",
        ano: ""
    });

    const [payAmount, setPayAmount] = useState(0)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async () => {
        // e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_live_oGKXj8EVJEdpCQ', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {
                    alert('Payment successful!');
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };
    const [catValue, setCatValue] = useState("Select gender")
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const openCats = () => {
        if (isOpen) {
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [breedValue, setBreedValue] = useState("Select breed")
    const [isOpenBreeds, setIsOpenBreeds] = useState(false);
    const breedRef = useRef(null);

    const openBreeds = () => {
        if (isOpenBreeds) {
            setIsOpenBreeds(false)
        }
        else {
            setIsOpenBreeds(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (breedRef.current && !breedRef.current.contains(event.target)) {
                setIsOpenBreeds(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    const checkDets = () => {
        if (
            formData.name == "" || formData.email == "" || formData.age == "" || formData.phone == "" || formData.breed == "" || formData.gender == ""

        ) {
            alert("Please fill all the dets")
        }
        else if (formData.phone.length < 10 || formData.phone.length > 14) {
            alert("Enter a valid phone number")
        }
        else if (!formData.email.includes("@")) {
            alert("Enter a valid email address")
        }
        else if (formData.ano.length != 12) {
            alert("Please enter 12-digits aadhar card number")
        }
        else {
            handlePayment()
        }
    }




    return (
        <div className="Residentssss">

            <div className="lower-div-head sphm">
                <Nav />
                <div className="adhd">
                    <p className="head-text">
                        Surrender your Dog
                    </p>

                    <div className="resident-text-box">
                        <p className="resident-text">
                            We understand that surrendering a beloved pet can be a difficult decision. Whether due to unforeseen circumstances, changes in living situations, or any other reason, we are here to offer support and provide a safe haven for your dog.At We Positive Care Chitragupt Foundation, we are committed to the well-being and happiness of every dog in our care.
                        </p>
                    </div>
                </div>

            </div>

            <div className="lower-div rld">


                <div className="lower-div-content">
                    {/* <Iframe url="https://www.amazon.in/hz/wishlist/ls/O3M8FIH3J8IE?ref_=wl_share" className="wishlist"/> */}

                    <div className="importance-donation">
                        <p className="imp-head">
                            Why surrender to us ?
                        </p>
                        <ul className="key-donate" >
                            <li className="keyd">Experienced and compassionate staff dedicated to the welfare of dogs.</li>
                            <li className="keyd">Safe and comfortable facilities where dogs can thrive.</li>
                            <li className="keyd">Veterinary care, nutritious meals, and enriching activities provided daily.</li>
                            <li className="keyd">Extensive adoption network to find loving forever homes for surrendered dogs.</li>
                        </ul>
                    </div>
                    <br />
                    <div className="resident-text-box ">
                        <p className="resident-text sbtext">

                            Please fill this surrender form and pay our charges as we will take care of the for the rest of dog's life.






                        </p>
                    </div>
                    <br />
                    <div className="contact-form sdform"  >
                        <input className="contact-inp" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        {/* <p className="error-text">Please enter your name</p> */}
                        <input className="contact-inp" type="tel" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} required />
                        {/* <p className="error-text">Please enter your phone number</p> */}
                        <input className="contact-inp" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        {/* <p className="error-text">Please enter your email address</p> */}
                        <input className="contact-inp" type="number" name="ano" placeholder="Aadhar Card Number" value={formData.ano} onChange={handleChange} required />
                        <input className="contact-inp" type="text" name="dname" placeholder="Dog Name" value={formData.dname} onChange={handleChange} required />

                        <div class="dropdown" ref={dropdownRef}>
                            <div onClick={openCats} className="drpbox">
                                <p class="dropbtn">{catValue}</p>
                                <img className={isOpen ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpen && (
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setCatValue("Male"); setIsOpen(false); setFormData({ ...formData, gender: "male" }); }}>Male</p>
                                    <p href="#" onClick={() => { setCatValue("Female"); setIsOpen(false); setFormData({ ...formData, gender: "female" }); }}>Female</p>

                                </div>
                            )}
                        </div>
                        {/* <p className="error-text">Please select the gender</p> */}
                        <div class="dropdown" ref={breedRef}>
                            <div onClick={openBreeds} className="drpbox">
                                <p class="dropbtn">{breedValue}</p>
                                <img className={isOpenBreeds ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpenBreeds && (
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setBreedValue("Indian"); setIsOpenBreeds(false); setPayAmount(11000); setFormData({ ...formData, breed: "indian", amount: 11000 }); }}>Indian</p>
                                    <p href="#" onClick={() => { setBreedValue("Other"); setIsOpenBreeds(false); setPayAmount(21000); setFormData({ ...formData, breed: "other", amount: 21000 }); }}>Other</p>

                                </div>
                            )}
                        </div>
                        {/* <p className="error-text">Please select the breed</p> */}
                        <input className="contact-inp" type="number" name="age" placeholder="Dog Age (in yrs)" value={formData.age} onChange={handleChange} required />
                        {/* <p className="error-text">Please enter the dog's age</p> */}
                        <button className="submit-inp"  >
                            Submit and Pay ₹{payAmount}/-
                        </button>
                        
                    </div>



                </div>
            </div>
        </div>
    )
}

export default Surrender