import "../../App.scss"
import Iframe from "react-iframe"
import share from "./share.png"
import { useState } from "react";
import qr from "./qr.png"
import Nav from "../nav/nav";


function Donate() {


    const [formData, setFormData] = useState({
        email: '',
        phone: '',
        amount: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_live_NY60PWviQMXQBK', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'We Positive Care Chitragupt Foundation',
                description: 'Help animals to live their life happily',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {
                    alert('Payment successful!');
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };


    return (
        <div className="Residentssss">

            <div className="lower-div-head dphm">
                <Nav />
                <div className="adhd">
                    <p className="head-text">
                        Make a Donation
                    </p>

                    <div className="resident-text-box">
                        <p className="resident-text">
                            Welcome to our donation page, where every contribution goes a long way in saving and improving the lives of animals in need. Your generosity helps us provide food, shelter, medical care, and love to animals who have been neglected, abused, or abandoned.








                        </p>
                    </div>
                </div>

            </div>

            <div className="lower-div rld">


                <div className="lower-div-content">
                    {/* <Iframe url="https://www.amazon.in/hz/wishlist/ls/O3M8FIH3J8IE?ref_=wl_share" className="wishlist"/> */}

                    <div className="importance-donation">
                        <p className="imp-head">
                            Your money will go towards:
                        </p>
                        <ul className="key-donate" >
                            <li className="keyd">Medicines and medical supplies for animals</li>
                            <li className="keyd">Food for animals</li>
                            <li className="keyd">Transportation expenses for animal rescues</li>
                            <li className="keyd">Vet and animal welfare staff salaries</li>
                        </ul>
                    </div>

                    <div className="donation-methods">
                        <div className="donation-method">
                            <p className="donation-method-head">
                                1) Amazon Wishlist
                            </p>
                            <div className="donation-method-lower">
                                <p className="dm-det">
                                    We believe in the power of community and generosity, and we're immensely grateful for your willingness to support our mission. By purchasing items from our Amazon Wishlist, you're not only helping us meet practical needs but also contributing to the betterment of our cause. Every item, no matter how big or small, makes a significant difference in the lives we touch.

                                </p>
                                <div className="wishlist-link-box">
                                    <p className="wishlist-text">
                                        Amazon Wishlist
                                    </p>
                                    <img className="redirect" src={share} />
                                </div>
                            </div>
                        </div>

                        <div className="donation-method">
                            <p className="donation-method-head">
                                2) Bank Transfer
                            </p>
                            <div className="donation-method-lower">
                                <p className="bank-dets">
                                    Bank Name: Axis Bank
                                </p>
                                <p className="bank-dets">
                                    Account Name: We Positive Care Chitragupt Foundation
                                </p>
                                <p className="bank-dets">
                                    Account Number: 923020001833720
                                </p>
                                <p className="bank-dets">
                                    Accoount type: Current
                                </p>
                                <p className="bank-dets">
                                    IFSC Code: UTIB0004246
                                </p>
                            </div>
                        </div>

                        <div className="donation-method">
                            <p className="donation-method-head">
                                3) Debit card or Credit card
                            </p>
                            <div className="donation-method-lower">
                                <p className="dm-det">
                                    There are many options other than debit/credit cards like UPI, Wallets, Pay Later, PayPal, etc. to make a donation.
                                    <br />
                                    If you are making a donation outside India then you can donate us through PayPal after filling the form.
                                    <br />
                                    Reciept will be sent via email filled by you in the donation form.
                                </p>
                                <form className="contact-form pform" onSubmit={handlePayment} >
                                    <input className="contact-inp" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                                    <input className="contact-inp" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                                    <input className="contact-inp" type="number" name="amount" placeholder="Amount" value={formData.amount} onChange={handleChange} required />
                                    <input type="submit" className={(formData.email.length == 0 && formData.phone.length == 0 && formData.amount.length == 0) ? "submit-inp disabled" : "submit-inp"} value="Donate" />
                                </form>
                            </div>
                        </div>

                        <div className="donation-method">
                            <p className="donation-method-head">
                                4) UPI ids and QR codes
                            </p>
                            <div className="donation-method-lower">
                                <p className="dm-det">
                                    You can donate us via UPI ids or Phone number provided below by any payment app or just scan the qr code to donate us.
                                </p>

                                <div className="bank-dets-box">
                                    <p className="bank-dets ">
                                        GPay/PhonePe/Paytm: <span className="bold-det">+91 9312201466</span>
                                    </p>

                                    <p className="bank-dets ">
                                        UPI id: <span className="bold-det">9312201466@paytm</span>
                                    </p>
                                    {/* <p className="bank-dets ">
                                        UPI id: <span className="bold-det">upiid2@xyz</span>
                                    </p>
                                    <p className="bank-dets ">
                                        UPI id: <span className="bold-det">upiid3@xyz</span>
                                    </p> */}


                                </div>

                                <div className="qr-codes">
                                    {/* <img className="qr-code" src={qr} />
                                    <img className="qr-code" src={qr} />
                                    <img className="qr-code" src={qr} />
                                    <img className="qr-code" src={qr} /> */}
                                </div>

                            </div>
                        </div>

                        {/* <div className="donation-method">
                            <p className="donation-method-head">
                                5) Milaap Fundraiser
                            </p>
                            <div className="donation-method-lower">
                                <p className="dm-det">
                                    We're on a mission to make a difference, and your support means the world to us! Click the link below to join our Milaap fundraiser and become a part of something truly impactful. Together, we can create positive change and spread hope to those in need. Thank you for your generosity and for being a beacon of light in our journey.
                                </p>
                                <div className="wishlist-link-box">
                                    <p className="wishlist-text">
                                        Milaap Fundraiser
                                    </p>
                                    <img className="redirect" src={share} />
                                </div>
                            </div>
                        </div> */}

                        <div className="donation-method">


                            <p className="dm-det">
                                Together, we can make a difference and give hope to animals who deserve a second chance at happiness. Thank you for your kindness and compassion.
                            </p>


                        </div>


                    </div>

                </div>
            </div>
        </div>
    )
}

export default Donate;