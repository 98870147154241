import "../../../App.scss"
import imagess from "./images.json"
import closee from "./close.png"
import osh from "./0.jpeg"
import { useState } from "react"

function IndexShel() {

    const [currentSrc, setCUrrentSrc] = useState()

    const closePD = () => {
        document.getElementById("PictureDisplay").style.display = "none"
    }

    const openPD = async (x) => {
        await setCUrrentSrc(require(`./${x.image}`))
        document.getElementById("PictureDisplay").style.display = "flex"
    }





    return (

        <>
            <div className="PictureDisplay" id="PictureDisplay">
                <div className="pdbox">
                    <div className="pdbox-head">
                        <img className="close" src={closee} onClick={closePD} />
                    </div>
                    <div className="pdbox-img-box">
                        <img className="pdbox-img" src={currentSrc} />
                    </div>
                </div>
            </div>
            <div className="MainSec shel-imgs-div">
                <div className="img-shelter-gallery">
                    {/* <p className="head-text htext">
                    Shelter Images
                </p> */}
                    <div className="images-shel">
                        {
                            imagess.map((i) => (
                                <img onClick={() => { openPD(i) }} className="image-shel" src={require(`./${i.image}`)} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </>

    )
}


export default IndexShel