import Iframe from "react-iframe";
import "../../App.scss"
import rescue from "./rescue.jpeg"
import care from "./care.jpeg"
import vet from "./vet.jpeg"
import adopt from "./adopt.jpeg"
import shelter from "./shelter.jpeg"
import loc from "./loc.png"
import wards from "./wards.jpeg"
import Nav from "../nav/nav";
import phone from "./phone.png"
import mail from "./mail.png"
import tv from "./tv.png"
import { Link } from "react-router-dom";
import adopts from "../adopt/adoption.json"
import { useEffect, useRef, useState } from "react";
import { firestore } from "../../firebase";
import firebase from "firebase/compat/app";
import cross from "./close.png"
import success from "./success.png"
import warning from "./warning.png"
import da from "./da.png"
import janwp from "./janwp.jpg"



function Home() {




    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        amount: 0
    });
    const [currentAnimal, setCurrentAnimal] = useState(0)

    const [currentAnimalName, setCurrentAnimalName] = useState(0)

    const [currentAnimalSponsor, setCurrentAnimalSponsor] = useState(0)

    const [currentAnimalNameSponsor, setCurrentAnimalNameSponsor] = useState(0)

    const [payAmount, setPayAmount] = useState(0)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_test_nUKVQmPiWBQbzE', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {

                    const paymentId = response.razorpay_payment_id;
                    document.getElementById("adoptFormSponsor").style.display = "none"
                    document.getElementById("thanksBoxSponsor").style.display = "flex"
                    document.getElementById("fillTextSponsor").style.display = "none"

                    const docId = firestore.collection("sponsors").doc().id
                    firestore.collection("sponsors").doc(docId).set({
                        id: docId,
                        animalId: currentAnimal,
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        timePeriod: catValue,
                        animalName: currentAnimalName,
                        amount: payAmount,
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        paymentId: paymentId
                    }, { merge: true })
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const docId = firestore.collection("adoptions").doc().id
        firestore.collection("adoptions").doc(docId).set({
            id: docId,
            animalId: currentAnimal,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            animalName: currentAnimalName,
            date: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })
        document.getElementById("adoptForm").style.display = "none"
        document.getElementById("thanksBox").style.display = "flex"
        document.getElementById("fillText").style.display = "none"
    }

    const closeAdopt = () => {
        document.getElementById("adoptForm").style.display = "flex"
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("overlayAdoptDiv").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimal(0)
        setCurrentAnimalName("")
    }


    const openAdopt = (aid, aname) => {
        setCurrentAnimal(aid)
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setCurrentAnimalName(aname)
        document.getElementById("overlayAdoptDiv").style.display = "flex"
        document.getElementById("adoptForm").style.display = "flex"


    }

    const openSponsor = (aid, aname) => {
        setCatValue("Select period")
        setCurrentAnimal(aid)
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        document.getElementById("errorBoxSponsor").style.display = "none"
        setCurrentAnimalName(aname)
        document.getElementById("overlaySponsorDiv").style.display = "flex"
        document.getElementById("adoptFormSponsor").style.display = "flex"
        setPayAmount(0)
    }
    const [catValue, setCatValue] = useState("Select period")
    const closeSponsor = () => {
        setCatValue("Select period")
        document.getElementById("adoptFormSponsor").style.display = "flex"
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("errorBoxSponsor").style.display = "none"
        document.getElementById("overlaySponsorDiv").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimalSponsor(0)
        setCurrentAnimalNameSponsor("")
        setPayAmount(0)
    }





    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const openCats = () => {
        if (isOpen) {
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);








    return (
        <>
            <div id="overlayAdoptDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillText" className="head-div">
                        <p>Fill the form to adopt {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeAdopt} src={cross} />

                    </div>
                    <form id="adoptForm" className="payment-sec" onSubmit={handleSubmit}  >
                        <input className="contact-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="contact-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="contact-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                        <input className="contact-inp af" type="text" name="address" placeholder="Full address" value={formData.address} onChange={handleChange} required />
                        <input type="submit" className="submit-inp" value="Submit" />
                    </form>
                    <div id="thanksBox" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Your details have been submitted successfully.
                        </p>
                        <p className="teamText">
                            Our team will reach you shortly.
                        </p>
                        <button onClick={closeAdopt} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>

            <div id="overlaySponsorDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillTextSponsor" className="head-div">
                        <p>Fill the form to sponsor {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeSponsor} src={cross} />

                    </div>
                    <form id="adoptFormSponsor" className="payment-sec" onSubmit={(e) => {
                        handlePayment(e)
                    }}  >
                        <div class="dropdown" ref={dropdownRef}>
                            <div onClick={openCats} className="drpbox">
                                <p class="dropbtn">{catValue}</p>
                                <img className={isOpen ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpen && (
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setCatValue("1 month"); setIsOpen(false); setPayAmount(1); setFormData({ ...formData, amount: 1 }); }}>1 month</p>
                                    <p href="#" onClick={() => { setCatValue("3 months"); setIsOpen(false); setPayAmount(300); setFormData({ ...formData, amount: 300 }); }}>3 months</p>
                                    <p href="#" onClick={() => { setCatValue("6 months"); setIsOpen(false); setPayAmount(600); setFormData({ ...formData, amount: 600 }); }}>6 months</p>
                                    <p href="#" onClick={() => { setCatValue("12 months"); setIsOpen(false); setPayAmount(1200); setFormData({ ...formData, amount: 1200 }); }}>12 months</p>
                                </div>
                            )}
                        </div>
                        <input className="contact-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="contact-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="contact-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />

                        <input type="submit" className={catValue == "Select period" ? "submit-inp disabled" : "submit-inp"} value={"Pay Rs." + payAmount} />
                    </form>
                    <div id="thanksBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Thanks you for sponsoring {currentAnimalName} for {catValue}
                        </p>
                        <p className="teamText">
                            We have sent you the receipt via email
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>

                    <div id="errorBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={warning} />
                        <p className="thanksText errorText">
                            Payment failed
                        </p>
                        <p className="teamText errorText">
                            Please try again
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>


            <div className="Home">
                <div className="home-upper">
                    <Nav />
                    <div className="home-upper-sub">
                        <div className="upper-left">
                            {/* <p className="welcome">Welcome to </p> */}
                            <p className="name">We Positive Care Chitragupt Foundation</p>
                            <p className="welcome">Home for all needy animals </p>
                            <div className="buttons">
                                <a href="tel:+919312201466" className="helpline hp" >
                                    Helpline
                                </a>

                                <a href="/donate" className="helpline donate" >
                                    Donate
                                </a>

                            </div>
                        </div>

                        <div className="upper-right">
                            <Iframe url="https://www.youtube.com/embed/sW3KCnj66X8" className="yt-video"></Iframe>
                        </div>
                    </div>
                </div>
                <div className="home-lower">
                    <div className="image-div">
                        <img className="jan-img" src={janwp} />
                    </div>

                    <div className="lower-div">
                        <div className="lower-div-head">
                            <p className="head-text htext">
                                What we do ?
                            </p>

                        </div>
                        <div className="lower-div-content main-card-box">
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Rescue</p>
                                    <p className="lower-desc">
                                        We rescue injured animals to cure them.
                                    </p>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={shelter} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Shelter</p>
                                    <p className="lower-desc">
                                        We provide well maintained shelter for animals.
                                    </p>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={care} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Care</p>
                                    <p className="lower-desc">
                                        We provide compassionate care to animals for their better recovery.
                                    </p>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={vet} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Veterinary Treatment</p>
                                    <p className="lower-desc">
                                        We provide right and needed treatment to animals.
                                    </p>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={adopt} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Adopt</p>
                                    <p className="lower-desc">
                                        You can give a lovely animal a lovely home
                                    </p>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={wards} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head wwd">Seperate Wards</p>
                                    <p className="lower-desc">
                                        We have seperate wards for infected animals like a ward for parvo positive and a ward for distemper positive.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <div className="lower-div sdd">
                        <div className="lower-div-head">
                            <p className="head-text htext">
                                Adopt
                            </p>

                        </div>
                        <div className="lower-div-content main-card-box">

                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                        <p onClick={() => { openAdopt(0, "Jackie") }} className="cb ab">
                                            Adopt
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                        <p onClick={() => { openAdopt(0, "Jackie") }} className="cb ab">
                                            Adopt
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                        <p onClick={() => { openAdopt(0, "Jackie") }} className="cb ab">
                                            Adopt
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="lower-div sdd">
                        <div className="lower-div-head">
                            <p className="head-text htext">
                                Our Resident Animals
                            </p>

                        </div>
                        <div className="lower-div-content main-card-box">
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>
                            <div className="main-card">
                                <div className="card-upper">
                                    <img className="cardImg" src={rescue} />
                                </div>
                                <div className="card-lower">
                                    <p className="card-lower-head">Jackie</p>
                                    <p className="lower-desc">
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry
                                    </p>
                                    <div className="card-buttons">
                                        <p onClick={() => { openSponsor(0, "Jackie") }} className="cb sb">
                                            Sponsor
                                        </p>
                                       
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div> */}


                    <div className="lower-div sdd" id="contact" >
                        <div className="lower-div-head">
                            <p className="head-text htext">
                                Contact Us
                            </p>

                        </div>
                        <div className="lower-div-content contact-box">
                            <div className="contact-left">
                                <form className="contact-form">
                                    <input className="contact-inp" placeholder="Full Name" type="text" required />
                                    <input className="contact-inp" placeholder="Email" type="email" />
                                    <input className="contact-inp" placeholder="Phone number" type="phone" required />
                                    <textarea className="contact-inp" placeholder="Write your message here.." type="text" required />
                                    <input type="submit" className="submit-inp" value="Submit" />
                                </form>
                                {/* <p className="or" >OR</p>
                                <div className="con-dets">
                                    <div className="con-det">
                                        <img className="det-img phi" src={phone} />
                                        <p className="det-text">+91 9312201466</p>
                                    </div>
                                    <div className="con-det">
                                        <img className="det-img" src={mail} />
                                        <p className="det-text">wepositivechitraguptfoundation@gmail.com</p>
                                    </div>
                                    <div className="con-det">
                                        <img className="det-img" src={loc} />
                                        <p className="det-text">Aakash Vihar, Brahmpuri Colony, Ranhola, New Delhi - 110041</p>
                                    </div>
                                </div> */}
                            </div>
                            <div className="contact-right">
                                <div>
                                    <Iframe
                                        url={`https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3501.119392790364!2d77.02837587550172!3d28.65614357565189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDM5JzIyLjEiTiA3N8KwMDEnNTEuNCJF!5e0!3m2!1sen!2sin!4v1710776049419!5m2!1sen!2sin`}
                                        width="600"
                                        height="450"
                                        loading="lazy"
                                        allowFullScreen
                                        className="map"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            </div>


        </>

    )
}

export default Home;