import logo from './logo.svg';
import './App.scss';
import Home from './components/home/home';
import { Route, Routes, useLocation } from 'react-router-dom';
import Residents from './components/residents/residents.jsx';
import Nav from './components/nav/nav';
import Adopt from './components/adopt/adopt';
import Donate from './components/donate/donate';
import Surrender from './components/surrender/surrender';
import Founders from './components/founder/founder';
import Shelter from './components/shelter/shelter';
import PreloadImages from './preloader/preloader';
import Footer from './components/footer/footer';
import MainSec from './components/shelter/main/mainSec.jsx';
import Distemper from './components/shelter/distemper/mainSec.jsx';
import Paralysed from './components/shelter/paralysed/mainSec.jsx';
import IndexShel from './components/shelter/indexShel/mainSec.jsx';
import { useEffect } from 'react';
import up from "./up.png"
import Terms from './components/terms/terms.js';
import Refund from './components/refund/refund.js';
import PrivacyT from './components/privacy/privacy.js';

function App() {



  const location = useLocation();

  const isActive = (path) => location.pathname === path;


  useEffect(() => {
    const handleScroll = () => {
      // Your scroll-related logic or function call here
      if (window.scrollY > 10) {
        document.getElementById("scrollUp").style.display = "flex"
      }
      else if (window.scrollY < 10) {
        document.getElementById("scrollUp").style.display = "none"
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener('scroll', handleScroll);

    // Detach the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const clickScroll = (e) => {
    e.preventDefault()


    window.scrollTo(0, 0);
  }



  return (
    <>


      <div onClick={clickScroll} id='scrollUp' className='scrollup'>
        <img className='up' src={up} />
      </div>

      <div className="App">

        <PreloadImages />
        {/* {
  isActive("/") ? null : <Nav />
} */}
        <Routes>
          <Route path='/' element={<Home />} />
          {/* <Route path='/founders' element={<Founders />} /> */}
          <Route path='/shelter' element={<Shelter />} >
            <Route index element={<IndexShel />} />
            <Route path='main-section' element={<MainSec />} />
            <Route path='distemper-section' element={<Distemper />} />
            <Route path='paralysed-section' element={<Paralysed />} />
          </Route>
          <Route path='/chitragupt-foundation' element={<Home />} />
          <Route path='/resident-animals' element={<Residents />} />
          <Route path='/adopt' element={<Adopt />} />
          <Route path='/donate' element={<Donate />} />
          <Route path='/surrender' element={<Surrender />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/refund-policy' element={<Refund />} />
          <Route path='/privacy-policy' element={<PrivacyT />} />
        </Routes>
        <Footer />

      </div>
    </>
  );
}

export default App;
