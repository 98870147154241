import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';

firebase.initializeApp({
    // apiKey: "AIzaSyA6JEdFRIw05uZ4GDmBp_yaT0udW6QR2nQ",
    // authDomain: "aashiyana-2f089.firebaseapp.com",
    // projectId: "aashiyana-2f089",
    // storageBucket: "aashiyana-2f089.appspot.com",
    // messagingSenderId: "122135214981",
    // appId: "1:122135214981:web:99e65d752bf9f571d122ec",
    // measurementId: "G-GN6D3EC0T9"

    apiKey: "AIzaSyD78qgxIvDt0gcdstDMLVXYVq29WGj8TMI",
    authDomain: "aashiyanabynaman-6821e.firebaseapp.com",
    projectId: "aashiyanabynaman-6821e",
    storageBucket: "aashiyanabynaman-6821e.appspot.com",
    messagingSenderId: "523682057083",
    appId: "1:523682057083:web:4c250fc452603ebcabcbde",
    measurementId: "G-C2JYFB3E5J"

})
const auth = firebase.auth();
const firestore = firebase.firestore();


export { auth, firestore };
