import Nav from "../nav/nav";

function Refund() {

    return (
        <>
            <Nav />
            <div className="PrivacyPage">

                <div className="home-box" >
                    <div className="welcome-boxa nth">
                        <p className="welcomea">Refund Policy</p>
                    </div>



                    <div className="content-box">
                        <div >
                            <p className="home-content bt" > REFUNDS (IF APPLICABLE)</p>
                            <p className="home-content" >
                                If you accidently donated the amount more than you wanted to donate then please write us at wepositivechitraguptfoundation@gmail.com within 1 day of the transaction, the donated amount will be transferred to the source withing 3-4 working days.
                            </p>
                        </div>

                        <div>
                            <p className="home-content bt" > CONTACT INFORMATION </p>
                            <p className="home-content" >
                                Questions about the Terms of Service should be sent to us at wepositivechitraguptfoundation@gmail.com.
                            </p>
                        </div>
                    </div>

                </div>

            </div>
        </>
    )



}

export default Refund;














