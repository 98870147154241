import "../../../App.scss"
import osh from "./0.jpeg"
import tsh from "./3.jpeg"
import thsh from "./12.jpeg"

function MainSec() {
    return (
        <div className="MainSec">
            <div className="shel-main-dets">
                <div className="main-det">
                    <p className="det-head">
                        Overview
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Welcome to our main shelter, a cornerstone of compassion and support for individuals experiencing homelessness or crisis. Here, we provide more than just a roof over their heads; we offer a safe haven where dignity is restored, and hope is renewed. Our dedicated team provides comprehensive services, from basic needs to personalized support plans, empowering residents to rebuild their lives and reclaim their independence. Join us in our mission to create a community where everyone has a place to call home and the opportunity to thrive. Together, we can make a lasting impact and create a brighter future for all.
                        </p>
                        <img className="shel-det-img" src={osh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Playground
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Welcome to our dog playground area, where tails wag and furry friends frolic in a safe and stimulating environment. Designed with the well-being of our canine companions in mind, this space is a haven for socialization, exercise, and boundless fun. From agility courses to interactive toys, our playground offers a range of activities to keep dogs mentally and physically engaged. Whether they're chasing balls, navigating obstacles, or simply enjoying the company of their fellow furry pals, this space is a testament to our commitment to providing enriching experiences for our four-legged friends. So come, let your dog unleash their inner joy and join the playful pack in our dog playground area.
                        </p>
                        <img className="shel-det-img" src={tsh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Gallery
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Our gallery area is designed with the comfort and enjoyment of our canine companions in mind, providing a serene space where they can relax and soak in the beauty of each season. Whether it's the warmth of summer, the refreshing rains of the monsoon, or the crisp chill of winter, our gallery offers a sheltered retreat where dogs can unwind and bask in the changing elements. With cozy seating and panoramic views, it's the ideal spot for dogs to lounge, watch the world go by, and savor the simple pleasures of each passing season. Come rain or shine, our gallery is a welcoming haven where dogs can find peace and contentment amidst nature's ever-changing tapestry.
                        </p>
                        <img className="shel-det-img thsh" src={thsh} />
                    </div>
                </div>
               
            </div>
        </div>
    )
}


export default MainSec