import { useEffect, useRef, useState } from "react";
import "../../App.scss"
import Nav from "../nav/nav";
import rescue from "./rescue.jpg"
import { firestore } from "../../firebase";
import firebase from 'firebase/compat/app';
import cross from "./close.png"
import success from "./success.png"
import warning from "./warning.png"
import da from "./da.png"
import adoptions from "./adoption.json"

function Adopt() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        address: '',
        amount: 0
    });
    const [currentAnimal, setCurrentAnimal] = useState(0)

    const [currentAnimalName, setCurrentAnimalName] = useState(0)

    const [currentAnimalSponsor, setCurrentAnimalSponsor] = useState(0)

    const [currentAnimalNameSponsor, setCurrentAnimalNameSponsor] = useState(0)

    const [payAmount, setPayAmount] = useState(0)

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handlePayment = async (e) => {
        e.preventDefault()
        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.async = true;
        document.body.appendChild(script);

        script.onload = () => {
            const options = {
                key: 'rzp_test_nUKVQmPiWBQbzE', // Replace with your Razorpay Key ID
                amount: formData.amount * 100, // amount in paisa
                currency: 'INR',
                name: 'Aashiyana Recovery Form',
                description: 'Test Payment',
                prefill: {
                    email: formData.email,
                    contact: formData.phone
                },
                handler: function (response) {

                    const paymentId = response.razorpay_payment_id;
                    document.getElementById("adoptFormSponsor").style.display = "none"
                    document.getElementById("thanksBoxSponsor").style.display = "flex"
                    document.getElementById("fillTextSponsor").style.display = "none"

                    const docId = firestore.collection("sponsors").doc().id
                    firestore.collection("sponsors").doc(docId).set({
                        id: docId,
                        animalId: currentAnimal,
                        name: formData.name,
                        email: formData.email,
                        phone: formData.phone,
                        timePeriod: catValue,
                        animalName: currentAnimalName,
                        amount: payAmount,
                        date: firebase.firestore.FieldValue.serverTimestamp(),
                        paymentId: paymentId
                    }, { merge: true })
                }
            };

            const rzp = new window.Razorpay(options);
            rzp.open();
        };
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const docId = firestore.collection("adoptions").doc().id
        firestore.collection("adoptions").doc(docId).set({
            id: docId,
            animalId: currentAnimal,
            name: formData.name,
            email: formData.email,
            phone: formData.phone,
            address: formData.address,
            animalName: currentAnimalName,
            date: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })
        document.getElementById("adoptForm").style.display = "none"
        document.getElementById("thanksBox").style.display = "flex"
        document.getElementById("fillText").style.display = "none"
    }

    const closeAdopt = () => {
        document.getElementById("adoptForm").style.display = "flex"
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("overlayAdoptDiv").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimal(0)
        setCurrentAnimalName("")
    }


    const openAdopt = (aid, aname) => {
        setCurrentAnimal(aid)
        document.getElementById("thanksBox").style.display = "none"
        document.getElementById("fillText").style.display = "flex"
        setCurrentAnimalName(aname)
        document.getElementById("overlayAdoptDiv").style.display = "flex"
        document.getElementById("adoptForm").style.display = "flex"


    }

    const openSponsor = (aid, aname) => {
        setCatValue("Select period")
        setCurrentAnimal(aid)
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        document.getElementById("errorBoxSponsor").style.display = "none"
        setCurrentAnimalName(aname)
        document.getElementById("overlaySponsorDiv").style.display = "flex"
        document.getElementById("adoptFormSponsor").style.display = "flex"
        setPayAmount(0)
    }
    const [catValue, setCatValue] = useState("Select period")
    const closeSponsor = () => {
        setCatValue("Select period")
        document.getElementById("adoptFormSponsor").style.display = "flex"
        document.getElementById("thanksBoxSponsor").style.display = "none"
        document.getElementById("errorBoxSponsor").style.display = "none"
        document.getElementById("overlaySponsorDiv").style.display = "none"
        document.getElementById("fillTextSponsor").style.display = "flex"
        setFormData({
            name: '',
            email: '',
            phone: '',
            address: '',
            amount: 0
        })
        setCurrentAnimalSponsor(0)
        setCurrentAnimalNameSponsor("")
        setPayAmount(0)
    }





    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const openCats = () => {
        if (isOpen) {
            setIsOpen(false)
        }
        else {
            setIsOpen(true)
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <>
            <div id="overlayAdoptDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillText" className="head-div">
                        <p>Fill the form to adopt {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeAdopt} src={cross} />

                    </div>
                    <form id="adoptForm" className="payment-sec" onSubmit={handleSubmit}  >
                        <input className="contact-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="contact-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="contact-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />
                        <input className="contact-inp af" type="text" name="address" placeholder="Full address" value={formData.address} onChange={handleChange} required />
                        <input type="submit" className="submit-inp" value="Submit" />
                    </form>
                    <div id="thanksBox" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Your details have been submitted successfully.
                        </p>
                        <p className="teamText">
                            Our team will reach you shortly.
                        </p>
                        <button onClick={closeAdopt} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>

            <div id="overlaySponsorDiv" className="overlayDiv">
                <div className="paymentSec overlayAdopt">
                    <div id="fillTextSponsor" className="head-div">
                        <p>Fill the form to sponsor {currentAnimalName} :-</p>
                        <img className="cross" onClick={closeSponsor} src={cross} />

                    </div>
                    <form id="adoptFormSponsor" className="payment-sec" onSubmit={(e) => {
                        handlePayment(e)
                    }}  >
                        <div class="dropdown" ref={dropdownRef}>
                            <div onClick={openCats} className="drpbox">
                                <p class="dropbtn">{catValue}</p>
                                <img className={isOpen ? "da daOpen" : "da"} src={da} />
                            </div>
                            {isOpen && (
                                <div className="dropdown-content">
                                    <p href="#" onClick={() => { setCatValue("1 month"); setIsOpen(false); setPayAmount(1); setFormData({ ...formData, amount: 1 }); }}>1 month</p>
                                    <p href="#" onClick={() => { setCatValue("3 months"); setIsOpen(false); setPayAmount(300); setFormData({ ...formData, amount: 300 }); }}>3 months</p>
                                    <p href="#" onClick={() => { setCatValue("6 months"); setIsOpen(false); setPayAmount(600); setFormData({ ...formData, amount: 600 }); }}>6 months</p>
                                    <p href="#" onClick={() => { setCatValue("12 months"); setIsOpen(false); setPayAmount(1200); setFormData({ ...formData, amount: 1200 }); }}>12 months</p>
                                </div>
                            )}
                        </div>
                        <input className="contact-inp af" type="text" name="name" placeholder="Full Name" value={formData.name} onChange={handleChange} required />
                        <input className="contact-inp af" type="email" name="email" placeholder="Email" value={formData.email} onChange={handleChange} required />
                        <input className="contact-inp af" type="tel" name="phone" placeholder="Phone" value={formData.phone} onChange={handleChange} required />

                        <input type="submit" className={catValue == "Select period" ? "submit-inp disabled" : "submit-inp"} value={"Pay Rs." + payAmount} />
                    </form>
                    <div id="thanksBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={success} />
                        <p className="thanksText">
                            Thanks you for sponsoring {currentAnimalName} for {catValue}
                        </p>
                        <p className="teamText">
                            We have sent you the receipt via email
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>

                    <div id="errorBoxSponsor" className="payment-sec thanks-box">
                        <img className="success" src={warning} />
                        <p className="thanksText errorText">
                            Payment failed
                        </p>
                        <p className="teamText errorText">
                            Please try again
                        </p>
                        <button onClick={closeSponsor} className="okay" >Okay!!</button>
                    </div>
                </div>
            </div>


            <div className="Residentssss">
                <div className="lower-div-head adhm">
                    <Nav />
                    <div className="adhd">
                        <p className="head-text">
                            Adopt or Sponsor
                        </p>

                        <div className="resident-text-box">
                            <p className="resident-text">
                                Welcome to our adoption page, where you can discover the remarkable animals eagerly awaiting their forever homes. Each resident has a unique tale of resilience and hope, ready to embark on a new chapter of their life with a loving family. From gentle cats seeking a cozy lap to call their own, to energetic dogs yearning for adventures with a devoted companion, our shelter is home to a diverse range of animals seeking companionship and care.








                            </p>
                        </div>
                    </div>

                </div>
                <div className="lower-div rld">


                    <div className="lower-div-content main-card-box">

                        {
                            adoptions.map((r) => (
                                <div className="main-card">
                                    <div className="card-upper">
                                        <img className="cardImg" src={require(`./${r.image}`)} />
                                    </div>
                                    <div className="card-lower">
                                        <p className="card-lower-head">{r.name}</p>
                                        <p className="lower-desc">
                                            {
                                                r.desc
                                            }
                                        </p>
                                        <div className="card-buttons">
                                            <p onClick={() => { openSponsor(r.id, r.name) }} className="cb sb">
                                                Sponsor
                                            </p>
                                            <p onClick={() => { openAdopt(r.id, r.name) }} className="cb ab">
                                                Adopt
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }



                    </div>
                </div>
            </div>
        </>

    )
}

export default Adopt;