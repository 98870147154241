import "../../../App.scss"
import osh from "./0.jpeg"
import tsh from "./1.jpeg"
import thsh from "./2.jpeg"
import fsh from "./3.jpeg"

function Paralysed() {
    return (
        <div className="MainSec">

            <div className="shel-main-dets">
                <div className="main-det">
                    <p className="det-head">
                        Overview
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Welcome to our paralyzed section, a specialized area devoted to providing comprehensive care and support for dogs facing mobility challenges due to paralysis. In this dedicated space, we offer tailored rehabilitation programs and round-the-clock assistance to help these dogs regain their independence and quality of life. Our experienced team of veterinary professionals employs a range of therapies, including physical rehabilitation exercises, hydrotherapy, and assistive devices, to promote strength, mobility, and comfort for our paralyzed patients. With a compassionate approach and unwavering dedication, we strive to empower each dog in our paralyzed section to overcome obstacles and embrace life to the fullest. Join us in our commitment to making a difference, one step at a time, as we work tirelessly to restore hope and mobility to every dog in our care.
                        </p>
                        <img className="shel-det-img" src={osh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Playground
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            In our paralyzed section, we have created a unique and adaptive playground tailored to the specific needs of our canine friends facing mobility challenges. This specialized playground is designed to provide a safe and stimulating environment where paralyzed dogs can engage in physical activity, rehabilitation exercises, and mental stimulation. With carefully crafted ramps, gentle inclines, and padded surfaces, we ensure that every element of the playground is accessible and comfortable for dogs with varying degrees of mobility impairment. Here, they can enjoy the freedom of movement, build strength, and boost confidence under the guidance of our experienced rehabilitation team. Through play and exercise, we aim to enhance their quality of life, promote muscle development, and foster a sense of joy and fulfillment despite their physical limitations. Join us in witnessing the transformative power of play as we create moments of happiness and vitality for our paralyzed canine companions in our dedicated playground.
                        </p>
                        <img className="shel-det-img" src={tsh} />
                    </div>
                </div>
                <div className="main-det">
                    <p className="det-head">
                        Seperate Rooms
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            Within our paralyzed section, we have thoughtfully designed multiple separate rooms to cater to the varying comfort needs of our canine companions throughout the changing seasons. Each room is meticulously outfitted with climate control features, ensuring a cozy and temperature-regulated environment conducive to rest and relaxation, whether it be the sweltering heat of summer or the chilly winter months. In the summer, these rooms are equipped with cooling systems to provide relief from the heat, while during winter, they are insulated and heated to maintain warmth and comfort. With plush bedding and soothing ambient lighting, our aim is to create a tranquil retreat where paralyzed dogs can find respite from the elements and unwind in ultimate comfort. In these well-appointed rooms, we prioritize the well-being and comfort of our furry residents, ensuring they experience the utmost care and support year-round.


                        </p>
                        <img className="shel-det-img thsh" src={thsh} />
                    </div>
                </div>

                <div className="main-det">
                    <p className="det-head">
                        Well Maintained Rooms
                    </p>
                    <div className="shel-dets">
                        <p className="shel-det-text">
                            In our paralyzed section, we pride ourselves on maintaining impeccably cared-for rooms, meticulously designed to provide comfort and support for our beloved canine residents. Each room is thoughtfully equipped with essential amenities, including gentle ceiling fans and adjustable lighting to create a soothing atmosphere conducive to rest and relaxation. Plush bedding is provided to ensure optimal comfort for our paralyzed friends, offering soft and supportive surfaces for them to rest upon. Additionally, our rooms are kept impeccably clean and hygienic, with regular cleaning schedules and meticulous attention to detail. We understand the importance of creating a nurturing environment where our paralyzed dogs feel safe and secure, and our well-maintained rooms are a testament to our commitment to their well-being. From the moment they enter, our canine companions are enveloped in warmth and care, ensuring they receive the utmost comfort and support during their stay with us.


                        </p>
                        <img className="shel-det-img thsh" src={fsh} />
                    </div>
                </div>

            </div>
        </div>
    )
}


export default Paralysed